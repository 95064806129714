import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
// import RecentPosts from '../components/RecentPosts'
// import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  // featuredimage,
  tableOfContents,
  tags,
  title,
  date,
  helmet,
  recentPosts,
  allTags
}) => {
  const PostContent = contentComponent || Content

  return (
    <section>
      {helmet || ''}
      <div className="max-w-postpage m-auto">
        <div className="flex flex-wrap justify-around">
          <div className="w-full lg:w-content lg:mx-2">
            <section className="bg-white mx-4 pb-6 lg:mx-0">
              <section className="mb-4 py-6">
                <p className="text-gray-500 px-6 tracking-widest">
                  {date}
                </p>
                <h1 className="font-bold border-none leading-relaxed text-3xl px-6 pt-2 pb-4">
                  {title}
                </h1>
                {/* <p className="text-lg p-6 leading-loose">{description}</p> */}
                <div className="mx-6">
                  {tags && tags.length ? (
                    <div>
                      {tags && tags.map((tag) => (
                        <Link 
                          to={`/tags/${kebabCase(tag)}/`} 
                          className="inline-block border border-gray-300 hover:border-gray-600 py-1 px-2 text-sm text-gray-700 font-light mr-2">{tag}
                        </Link>
                      ))}
                    </div>
                  ) : null}
                </div>
                {/* {featuredimage ? (
                  <div className="mt-8">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: featuredimage,
                        alt: `featured image thumbnail for post ${
                          title
                        }`
                      }}
                    />
                  </div>
                ) : null} */}
              </section>
              <PostContent content={content} className="content" />
            </section>
          </div>
          <div className="w-full lg:w-sidebar">
            <div className="hidden lg:block sticky top-0 py-4">
              <h3 className="text-xl pb-2 mb-4 border-b">Table of contents</h3>
              <div className="text-sm toc" dangerouslySetInnerHTML={{ __html: tableOfContents }}></div>
            </div>
            {/* <div className="mx-4 lg:mx-0">
              <h2 className="text-xl mb-6 mt-12 border-b">Recent posts</h2>
              <RecentPosts recentPosts={recentPosts}></RecentPosts>
            </div>
            <div className="mx-4 lg:mx-0">
              <h2 className="text-xl mb-6 mt-12">Tags</h2>
              {allTags && allTags.map(tag => (
                <div key={tag.fieldValue}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  // featuredimage: PropTypes.string,
  tableOfContents: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  recentPosts: PropTypes.array,
}

const BlogPost = ({ data }) => {
  const { 
    allMarkdownRemark: { group },
    markdownRemark: post,
    recentPosts
  } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        // featuredimage={post.frontmatter.featuredimage}
        tableOfContents={post.tableOfContents}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        recentPosts={recentPosts}
        allTags={group}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    recentPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      tableOfContents(
        maxDepth: 3
      )
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        description
        # featuredimage {
        #   childImageSharp {
        #     fluid(maxWidth: 120, quality: 100) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
        tags
      }
    },
    recentPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY.MM.DD")
            featuredpost
            # featuredimage {
            #   childImageSharp {
            #     fluid(maxWidth: 120, quality: 100) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
            tags
          }
        }
      }
    }
  }
`

